import {
    Box, CardMedia, Typography, Button, Link, Hidden, Grid, Menu, IconButton, Drawer, List, ListItem, ListItemText, ListItemButton, Collapse
} from '@mui/material';
import React, { useState } from 'react';
import { NavbarStyle } from './NavbarStyle';
import MenuIcon from '@mui/icons-material/Menu';
import WhatsApp from '../images/whatsapp .png';
import location from '../images/location.png';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../common utils/Colors';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import vikaspdf1 from '../../pages/images/pdf/vikaspdf1.pdf';
import vikaspdf2 from '../../pages/images/pdf/vikaspdf2.pdf';
import FAIRCODEMALAYALAM from '../../pages/images/pdf/FAIRCODEMALAYALAM.pdf';
import Balance_Sheet_As_At_31St_March_2023 from '../../pages/images/pdf/Balance_Sheet_As_At_31St_March_2023.pdf';
import Annual_General_Meeting from '../../pages/images/pdf/Annual_General_Meeting.pdf';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
function Navbar({ imageSrc, Paper1, sideBox2Button, whatsappImage, paymentcolor, pdf1, pdf2 }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    
    const Navigation = useNavigate()
   
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };


    const [openProduct, setOpenProduct] = useState(false);
    const [openAboutUs, setOpenAboutUs] = useState(false);
    const [openPolicy, setOpenPolicy] = useState(false);
    const [openDownloads, setOpenDowloads] = useState(false);
    


    const [anchorElProducts, setAnchorElProducts] = useState(null);
    const [anchorElAboutUs, setAnchorElAboutUs] = useState(null);
    const [anchorElPolicy, setAnchorElPolicy] = useState(null);
    const [anchorElDownloads, setAnchorElDownloads] = useState(null);
    // const [anchorElWorkingTime, setAnchorElWorkingTime] = useState(null);
    const handleOpenProducts = (event) => {
        setAnchorElProducts(event.currentTarget);
        setOpenProduct(!openProduct);
    };
    const handleOpenAboutUs = (event) => {
        setAnchorElAboutUs(event.currentTarget);
        setOpenAboutUs(!openAboutUs);
       
    };
    const handleOpenPolicy = (event) => {
        setAnchorElPolicy(event.currentTarget);
        setOpenPolicy(!openPolicy);
    };
    const handleOpenDownloads = (event) => {
        setAnchorElDownloads(event.currentTarget);
        setOpenDowloads(!openDownloads);
    };
    //   const handleOpenWorkingTime = (event) => {
    //     setAnchorElWorkingTime(event.currentTarget);
    //   };


    const handleClose = () => {
        setAnchorElProducts(null);
        setAnchorElAboutUs(null);
        setAnchorElPolicy(null);
        setAnchorElDownloads(null);
        // setAnchorElWorkingTime(null);
    };

    const handleSidebarOpen = () => {
        setSidebarOpen(true);
    }
    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };




    return (
        <div>
            <Box style={NavbarStyle.navbarStyle} >

                <Grid container spacing={0} justifyContent='space-between' alignItems="center">


                    <div style={{ paddingTop: '30px' }}  >
                        <img src={imageSrc} style={NavbarStyle.imageStyle} />

                    </div>


                    <Grid lg={10} display='flex' justifyContent='center' alignItems="center">
                        <Box style={NavbarStyle.sideBox}>
                            <Box style={NavbarStyle.sideBox1}>

                                <Hidden lgDown>
                                    <Box style={NavbarStyle.sideBox1First}>

                                        <Button style={NavbarStyle.sidebox1button} variant="subtitle1" onClick={() => Navigation("/gallery")}>Gallery</Button>
                                        <Button style={NavbarStyle.sidebox1button} variant="subtitle1" onClick={() => Navigation("/news")}>News</Button>
                                        <Button style={NavbarStyle.sidebox1button} variant="subtitle1" onClick={() => Navigation("/career")}>Career</Button>
                                        <Button style={{ color: 'black', textTransform: 'capitalize', }} aria-controls="dropdown-menu" aria-haspopup="true" onMouseEnter={handleOpenDownloads} endIcon={<ArrowDropDownIcon />}>
                                            Downloads
                                        </Button>
                                        <Menu id="dropdown-menu" anchorEl={anchorElDownloads} open={Boolean(anchorElDownloads)} onClose={handleClose} onMouseLeave={handleClose}  >
                                            <Button href={vikaspdf1} style={{display: 'flex',color: theme.BRAND_BUTTON,backgroundColor: 'transparent', borderRadius:0 }} 
                                             onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}}>
                                                Form No.15G
                                                </Button>
                                            <Button href={vikaspdf2}  style={{display: 'flex',color: theme.BRAND_BUTTON,backgroundColor: 'transparent',borderRadius:0 }}
                                            onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}}>
                                                Form No.15H</Button>
                                        </Menu>

                                        {/* <Menu id="dropdown-menu"anchorEl={anchorElWorkingTime}open={Boolean(anchorElWorkingTime)}onClose={handleClose}onMouseLeave={handleClose}  >
                                          <Typography style={{display: 'flex',color:theme.BRAND_BUTTON}} onClick={handleClose}>Monday-Saturday:9:00am to 5:30pm</Typography>
                                          </Menu>*/}
                                    </Box>

                                    <Box style={NavbarStyle.sideBox1Last}>

                                        <Typography color='black' variant="caption" display="block">Opening time:<Typography color='black' variant="caption" display="block">Mon-Sat:9:00am to 5:30pm</Typography></Typography>
                                        <IconButton variant="outlined">
                                            <img src={WhatsApp} style={NavbarStyle.notificationBellStyle} />
                                        </IconButton>
                                        {/* <IconButton variant="subtitle1">
                                            <SearchIcon />
                                        </IconButton> */}
                                        <IconButton variant="subtitle1">
                                            <CardMedia image={location} style={NavbarStyle.notificationBellStyle} />
                                        </IconButton>
                                        {/* <IconButton variant="subtitle1">
                                        <CardMedia image={NotificationBell} style={NavbarStyle.notificationBellStyle} />
                                    </IconButton> */}

                                    </Box>
                                </Hidden>


                            </Box>
                            <Hidden lgDown>

                                <Box style={NavbarStyle.sideBox2}>
                                    <Box style={NavbarStyle.sideBox21}>

                                        <Button style={NavbarStyle.sidebox21button} variant="subtitle1" onClick={() => Navigation("/")} >Home</Button>
                                        <Button style={NavbarStyle.sidebox21button} aria-controls="dropdown-menu" aria-haspopup="true" onMouseEnter={handleOpenProducts} endIcon={<ArrowDropDownIcon />}>
                                            Products
                                        </Button>
                                        <Menu id="dropdown-menu" anchorEl={anchorElProducts} open={Boolean(anchorElProducts)} onClose={handleClose} onMouseLeave={handleClose}  >
                                            <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.BRAND_BUTTON }} onClick={() => Navigation("/goldloan")}
                                            onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}}>
                                                Gold loan</Button>
                                            <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.BRAND_BUTTON }} onClick={() => Navigation("/personalloan")}
                                            onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}} >
                                                Personal Loan</Button>
                                            <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.BRAND_BUTTON }} onClick={() => Navigation("/businessloan")}
                                            onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}} >
                                                Consumer Loan</Button>
                                            <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.BRAND_BUTTON ,}} onClick={() => Navigation("/vehicleloan")}
                                             onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}}>
                                                Vehicle Loan</Button>
                                        </Menu>
                                        <Button style={NavbarStyle.sidebox21button} aria-controls="dropdown-menu" aria-haspopup="true" onMouseEnter={handleOpenAboutUs} endIcon={<ArrowDropDownIcon />}>
                                            About Us
                                        </Button>
                                        <Menu id="dropdown-menu" anchorEl={anchorElAboutUs} open={Boolean(anchorElAboutUs)} onClose={handleClose} onMouseLeave={handleClose}  >
                                            <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.BRAND_BUTTON }} onClick={() => Navigation("/aboutus")}
                                            onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}} >
                                                About Us</Button>
                                            <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.BRAND_BUTTON }} onClick={() => Navigation("/mdsdesk")}
                                            onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}} >
                                                MD's Desk</Button>
                                        </Menu>
                                        <Button style={NavbarStyle.sidebox21button} aria-controls="dropdown-menu" aria-haspopup="true" onMouseEnter={handleOpenPolicy} endIcon={<ArrowDropDownIcon />}>
                                            Investors & Policy
                                        </Button>
                                        <Menu id="dropdown-menu" anchorEl={anchorElPolicy} open={Boolean(anchorElPolicy)} onClose={handleClose} onMouseLeave={handleClose}  >
                                            <Button href={FAIRCODEMALAYALAM} style={{ ...NavbarStyle.sidebox21button2, color: theme.BRAND_BUTTON }} 
                                             onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}}>
                                                Fair pratice code</Button>
                                            <Button href={Balance_Sheet_As_At_31St_March_2023} style={{ ...NavbarStyle.sidebox21button2, color: theme.BRAND_BUTTON }} 
                                             onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}}>
                                                Balance Sheet As At 31St March,2023</Button>
                                            <Button href={Annual_General_Meeting} style={{ ...NavbarStyle.sidebox21button2, color: theme.BRAND_BUTTON }}  
                                            onMouseEnter={(e) => { e.target.style.backgroundColor = theme.BRAND_BUTTON2;  e.target.style.color = '#fff'; }}onMouseLeave={(e) => {e.target.style.backgroundColor = 'transparent';  e.target.style.color = theme.BRAND_BUTTON;}} >
                                                36'th' Anual General Meeting New</Button>

                                        </Menu>
                                        <Button style={{ textTransform: 'capitalize', }} variant="subtitle1" onClick={() => Navigation("/getintouch")}>Get in Touch</Button>

                                    </Box>
                                    <Box>
                                        <Button>
                                            <Link color={paymentcolor} style={NavbarStyle.sideBox2Link}> Make Payments</Link>
                                        </Button>
                                    </Box>
                                    <Box style={NavbarStyle.sideBox22}>

                                        <Button variant="contained" style={NavbarStyle.sideBox2Login}>{sideBox2Button}</Button>
                                    </Box>
                                </Box>

                            </Hidden >
                           {/* mobile view start */}  
                            <Hidden lgUp>

                                <Drawer anchor="right" open={sidebarOpen} onClose={handleSidebarClose}  > 
                                <Box style={{ backgroundColor: theme.BRAND_COLOR2, paddingTop: '80px'}}>
                                    <List style={{ backgroundColor: theme.BRAND_COLOR2, }} >

                                        <ListItem button style={{ color: '#fff' }} onClick={() => Navigation("/")}>
                                            <ListItemText primary="Home" />
                                        </ListItem>
                                        <ListItemButton style={NavbarStyle.sidebox21button}  onClick={handleOpenProducts}>
                                            <ListItemText primary="Product" />
                                            {openProduct ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={openProduct} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4, display:'block',backgroundColor:theme.BRAND_BUTTON2 }}>
                                                <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.WHITE_TEXT}} onClick={() => Navigation("/goldloan")}>Gold loan</Button>
                                                <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.WHITE_TEXT }} onClick={() => Navigation("/personalloan")}>Personal Loan</Button>
                                                <Button style={{ ...NavbarStyle.sidebox21button2,color: theme.WHITE_TEXT  }} onClick={() => Navigation("/businessloan")}>Consumer Loan</Button>
                                                <Button style={{ ...NavbarStyle.sidebox21button2,color: theme.WHITE_TEXT  }} onClick={() => Navigation("/vehicleloan")}>Vehicle Loan</Button>
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                          
                                        <ListItemButton style={NavbarStyle.sidebox21button} onClick={handleOpenAboutUs}>
                                            <ListItemText primary="About Us" />
                                            {openAboutUs ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={openAboutUs} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 ,display:'block',backgroundColor:theme.BRAND_BUTTON2 }}>
                                                <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.WHITE_TEXT}} onClick={() => Navigation("/aboutus")}>About Us</Button>
                                                <Button style={{ ...NavbarStyle.sidebox21button2, color: theme.WHITE_TEXT }} onClick={() => Navigation("/mdsdesk")}>MD's Desk</Button>
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                         
                                        <ListItemButton style={NavbarStyle.sidebox21button} onClick={handleOpenPolicy}>
                                            <ListItemText primary="Investors & Policy" />
                                            {openPolicy ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={openPolicy} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 ,display:'block',backgroundColor:theme.BRAND_BUTTON2 }}>
                                                <Button href={FAIRCODEMALAYALAM} style={{ ...NavbarStyle.sidebox21button2, color: theme.WHITE_TEXT }} >Fair pratice code</Button>
                                                <Button href={Balance_Sheet_As_At_31St_March_2023} style={{ ...NavbarStyle.sidebox21button2, color: theme.WHITE_TEXT}} >Balance Sheet As At 31St March,2023</Button>
                                                <Button href={Annual_General_Meeting} style={{ ...NavbarStyle.sidebox21button2, color:theme.WHITE_TEXT}}  >36'th' Anual General Meeting New</Button>
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                       
                                        
                                        {/* <ListItemButton onClick={handleClick}>
                                            <ListItemText primary="Inbox" />
                                            {open ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <ListItemText primary="Starred" />
                                                </ListItemButton>
                                            </List>
                                        </Collapse> */}
                                        <ListItem button style={{ color: '#fff' }} onClick={() => Navigation("/gallery")}>
                                            <ListItemText primary="Gallery" />
                                        </ListItem>
                                        <ListItem button style={{ color: '#fff' }} onClick={() => Navigation("/news")}>
                                            <ListItemText primary="News" />
                                        </ListItem>
                                        <ListItem button style={{ color: '#fff' }} onClick={() => Navigation("/career")}>
                                            <ListItemText primary="Career" />
                                        </ListItem>
                                        <ListItemButton style={NavbarStyle.sidebox21button} onClick={handleOpenDownloads}>
                                            <ListItemText primary="Downloads" />
                                            {openDownloads ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton >
                                        <Collapse in={openDownloads} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 ,display:'block',backgroundColor:theme.BRAND_BUTTON2 }} >
                                                <Button href={vikaspdf1} style={{ ...NavbarStyle.sidebox21button2, color: theme.WHITE_TEXT,}}>Form No.15G</Button>
                                                <Button href={vikaspdf2} style={{ ...NavbarStyle.sidebox21button2, color: theme.WHITE_TEXT}} onClick={handleClose}>Form No.15H</Button>
                                                </ListItemButton>
                                            </List>
                                        </Collapse> 
{/* 
                                        <ListItem button style={{ color: '#fff' }} onClick={handleSidebarClose}>
                                            <ListItemText primary="Working Time" />
                                        </ListItem> */}
                                        <ListItem button style={{ color: '#fff' }} onClick={handleSidebarClose}>
                                            <ListItemText primary="Make A Payment" />
                                        </ListItem>
                                        <ListItem>
                                            <Button style={{ backgroundColor: theme.BRAND_BUTTON2 }} variant="contained" onClick={handleSidebarClose}>
                                                {sideBox2Button}
                                            </Button>
                                        </ListItem>
                                    </List>
                                    </Box>
                                </Drawer>
                            </Hidden>

                            <Hidden lgUp>
                                <Box style={NavbarStyle.sideBoxlg}>

                                    <Box style={NavbarStyle.sideBox1Last}>



                                        <IconButton variant="subtitle1">
                                            <img src={WhatsApp} style={NavbarStyle.notificationBellStylelg} />
                                        </IconButton>
                                        {/* <IconButton variant="subtitle1">
                                            <SearchIcon />
                                        </IconButton> */}

                                        <IconButton variant="subtitle1">
                                            <CardMedia image={location} style={NavbarStyle.notificationBellStylelg} />
                                        </IconButton>
                                        {/* <IconButton variant="subtitle1"> */}
                                        {/* <CardMedia image={NotificationBell} style={NavbarStyle.notificationBellStylelg} />
                                        </IconButton> */}

                                        <IconButton style={NavbarStyle.menuIconStyle}
                                            color="inherit"
                                            aria-controls="menu"
                                            aria-haspopup="true"
                                            onClick={handleSidebarOpen}
                                        >
                                            <MenuIcon />
                                        </IconButton>


                                    </Box>
                                </Box>
                            </Hidden>


                        </Box>
                    </Grid>
                </Grid>

            </Box>
        </div>
    )
}

export default Navbar
