import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Button,Box, ListItemButton, createTheme, ThemeProvider, IconButton } from '@mui/material';
import { Footer2Style } from './Footer2Style';
import logo from '../images/logo.png';
import wpgreen from '../images/wpgreen.png';
import { Facebook, Instagram, LinkedIn, WhatsApp, YouTube } from '@mui/icons-material';
const listItemStyle = {
  listStyle: 'none', // Remove the bullets
};

function Footer2({ footerbg }) {
  const defaultTheme= createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
  })
  return (
   <ThemeProvider theme={defaultTheme}>
    <AppBar position="static" style={{ backgroundColor: footerbg, paddingTop:'20px'}}>
      
        <Toolbar>

          <Grid container spacing={0} columnSpacing={0} display='flex' justifyContent='center'>
            {/* Logo and Address */}
            <Grid item xs={12} sm={4} md={2} columnSpacing={0}>
              <Box>
              <img src={logo} alt="Your Logo" className="logo-img" style={{ height: 'auto', maxWidth: '100%' }} />
              <Typography variant='body' >
              <address style={Footer2Style.footertitle}>
                First Floor, Anjali Tower, Kavuvattam<br />
                Cherpulassery, Palakkad - Kerala<br />
                Pin: 679503
              </address>
            </Typography>
              </Box>
            </Grid>

            {/* Service */}
            <Grid item xs={12} sm={4} md={2.4}>
            <Typography variant="body1"  style={Footer2Style.footertitle}>
              
              Customer Care <br />
              <strong>9072 600 700</strong>
            </Typography>
            <Typography variant="body1"  style={Footer2Style.footertitle}>
              Mon - Sat: 9.00am to 5.30pm<br />
              Working Hours
            </Typography>
            <br/>
            
            <Button variant="outlined" style={Footer2Style.button1Style}>
              <Box component="img" src={wpgreen} alt="WhatsApp Icon" sx={{ maxWidth: '60%', height: 'auto' }} />
              </Button>
              <Box paddingTop={2}>
              <Button  variant="outlined" style={{...Footer2Style.button1Style,}}>
                Register Your Complaint
              </Button>
              </Box>
            </Grid>

            {/* Services */}
            <Grid item xs={12} sm={4} md={2.4}>
            <Typography variant="h6" style={{ ...Footer2Style.footertitle, paddingLeft: '30px' }}>
                Online
              </Typography>
              <List>
                <ListItem>
                <ListItemButton  variant='caption' style={Footer2Style.listbutton}>Online payment</ListItemButton>
                </ListItem>
                <ListItem>
                <ListItemButton  variant='caption' style={Footer2Style.listbutton}>Onlin enquiry</ListItemButton>
                </ListItem>
              </List>
             
            </Grid>

            {/* About Us */}
            <Grid item xs={12} sm={4} md={2.4}>
            <Typography variant="h6" style={{ ...Footer2Style.footertitle, paddingLeft: '30px' }}>
                Services
              </Typography>
              <List>
                <ListItem>
                  <ListItemButton  variant='caption'  style={{...Footer2Style.listbutton,width:'50px'}}>Gold Loan</ListItemButton>
                </ListItem>
                <ListItem>
                <ListItemButton  variant='caption' style={Footer2Style.listbutton}>Personal Loan</ListItemButton>
                </ListItem>
                <ListItem>
                <ListItemButton  variant='caption' style={Footer2Style.listbutton}>Vehicle Loan</ListItemButton>
                </ListItem>
                <ListItem>
                <ListItemButton  variant='caption' style={Footer2Style.listbutton}>Business Loan</ListItemButton>
                </ListItem>
                <ListItem>
                <ListItemButton  variant='caption' style={Footer2Style.listbutton}>Forex</ListItemButton>
                </ListItem>
              </List>
              
            </Grid>

            {/* Online */}
            
            <Grid item xs={12} sm={4} md={2.4}>
              
            <Typography variant="h6" style={{ ...Footer2Style.footertitle, paddingLeft: '30px' }}>
                About Us
              </Typography>
              <List>
                <ListItem>
                <ListItemButton  variant='caption' style={Footer2Style.listbutton}>About us</ListItemButton>
                </ListItem>
                <ListItem>
                <ListItemButton  variant='caption' style={Footer2Style.listbutton}>Media</ListItemButton>
                </ListItem>
                <ListItem>
                <ListItemButton  variant='caption' style={Footer2Style.listbutton}>Career</ListItemButton>
                </ListItem>
              </List>
             
          <Typography  variant="h6" style={{ ...Footer2Style.footertitle,  paddingLeft: '30px' }}>Get Social</Typography>
          <Box
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      p={2}
    >
     <IconButton href='https://www.facebook.com/vikasmoneylimited'> <Facebook style={{ color: 'black' ,}} /></IconButton>
     <IconButton href='https://www.instagram.com/vikasmoneylimited/'>  <Instagram style={{ color: 'black' }} /></IconButton> 
     <IconButton href='https://www.linkedin.com/authwall?trk=gf&trkInfo=AQEipVRuakFFygAAAYskbsSoYPkX1NRVIRcEik6os2_86Y4sDOv1oTAr6Wr2CukTLCdU7NbHpBkcDI_isc-tcEqa2elC9FmZMFnUIJgH_JaWVIaOef1YjD0mxSClm88sbtQ9RVE=&original_referer=https://www.vikasmoney.com/&sessionRedirect=https%3A%2F%2Fin.linkedin.com%2Fin%2Fvikas-money-limited-5108b51b1'>  <LinkedIn style={{ color: 'black' }} /></IconButton>  
     <IconButton href='https://www.youtube.com/@vikasmoneylimited9378'>  <YouTube style={{ color: 'black' }} /></IconButton> 
     <IconButton href='https://api.whatsapp.com/send/?phone=7592091777&text=Hello&type=phone_number&app_absent=0'> <WhatsApp style={{ color: 'black' }} /></IconButton>  
    </Box>
            </Grid>
          </Grid>
         
        </Toolbar>
        <Grid  display='flex' justifyContent='center'>
          <Grid item >
            <Box padding='0,5px,0,5px' textAlign='center'   >
            <Typography  variant='caption'   style={{ ...Footer2Style.footertitle, }}> Copyright © 2023 Vikas Money Ltd. Licensed by the Reserve Bank Of India.</Typography>
            <br/>
            <Typography variant='caption' style={{ ...Footer2Style.footertitle, }}> Powered by: Zourcelab Private Limited</Typography>
            </Box>
          </Grid>
          </Grid>
    </AppBar>
    </ThemeProvider>
  );
}

export default Footer2;
