export const theme={
    BRAND_COLOR1:'#71BF45',//vikasgreen
    BRAND_COLOR2:'#00668F',//vikasblue
    BRAND_BUTTON:'#00668F',//vikasblue
    BRAND_BUTTON2:'#71BF45',//vikasgreen
    BODY_COLOR:'#F5F6F8', //grey
    DARK_TEXT:'',
    WHITE_TEXT:'#fff',//white
    MAIN_HEADING:'',
    FOOTER_COLOR:'#ddd',//grey
    WHITE_BG:'#fff',//white
    BRAND_BORDER:'2px solid #ddd' //grey

}
export const colors = {
    primary: '#007bff',
    secondary: '#6c757d',
    success: '#28a745',
    danger: '#dc3545',
    warning: '#ffc107',
    info: '#17a2b8',
    light: '#f8f9fa',
    dark: '#000',
};
export const themeColor = {
    red: '#FF0000',
    blue: '#0000FF',
    green: '#00FF00',
    yellow: '#FFFF00',
    orange: '#FFA500',
    purple: '#800080',
    pink: '#FFC0CB',
    brown: '#A52A2A',
    teal: '#008080',
    gray: '#808080',
}