import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import logo from '../images/logo.png';
import wpgreen from '../images/wpgreen.png';
import { Footer2Style } from './Footer2Style';
function Footer({footerbg}) {
  const gridItemStyle = {
    alignItems: 'flex-start',
    paddingTop:'20px', 
    paddingBottom:'20px',
    //borderTop: '1px solid gray',
    
    
  };
  const button1Style = {
    color: '#fff',
    height: '60px',
    width:'100%', 
    borderColor: 'gray',// Adjust the height as needed
  };
  const button2Style = {
    color: 'black',
    height: '60px',
    width:'100%' ,
    borderColor: 'gray',// Adjust the height as needed for the second button
  };
  return (
    <AppBar position="static" style={{backgroundColor:footerbg, borderTop: '1px solid gray',}} >
      {/* Adjust the height above */}
      <Container maxWidth="lg">
        <div style={gridItemStyle} >
        <Toolbar>
          <Grid container spacing={3}>
          
            <Grid item xs={12} sm={4}>
              <img src={logo} alt="Your Logo" className="logo-img" style={{ height: 'auto', maxWidth: '100%' }} />
              <address  style={Footer2Style.footertitle}>
                First Floor, Anjali Tower, Kavuvattam<br />
                Cherpulassery, Palakkad - Kerala<br />
                Pin: 679503
              </address>
              <Typography variant="body1" style={Footer2Style.footertitle}>
                Copyright © 2023 Vikas Money Ltd. Licensed by the Reserve Bank Of India.
              </Typography>
              <Typography variant="body1"  style={Footer2Style.footertitle}>
                Powered by: Zourcelab Private Limited
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} className="mb-4 text-light" >
              <Typography variant="body1"  style={Footer2Style.footertitle}>
              
                Customer Care <br />
                <strong>9072 600 700</strong>
              </Typography>
              <Typography variant="body1"  style={Footer2Style.footertitle}>
                Mon - Sat: 9.00am to 5.30pm<br />
                Working Hours
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4} >
              <Button variant="outlined" style={button1Style}>
              <Box component="img" src={wpgreen} alt="WhatsApp Icon" sx={{ maxWidth: '60%', height: '90%' }} />
              </Button>
              <br/>
              <br/>
              <Button variant="outlined" style={button2Style}>
                Register Your Complaint
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        </div>
      </Container>
    </AppBar>
  );
}

export default Footer;
