import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import vikaspdf1 from './images/pdf/vikaspdf1.pdf'
import { Button, Typography } from '@mui/material';
import {Download } from '@mui/icons-material';
import Navbar from '../components/Navbar/Navbar';
function Downloads() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
  <div>
    <Navbar pdf1='vikaspdf1.pdf'/>
{/* <Typography>pdf viewer</Typography>
<Button href={vikaspdf1} variant='outlined' startIcon={<Download/>} >Dwnload</Button> */}

  </div>
  );
}

export default Downloads;
