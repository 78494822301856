import React from 'react';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Toolbar,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import NavbarMain from '../components/Navbar/NavbarMain';
import { theme } from '../common utils/Colors';
import Xtextfield from '../components/Xtextfield';
import {
  LocationCityRounded,
  AccessTimeFilled,
  LocalPhone,
} from '@mui/icons-material';
import Footer2 from '../components/Footer/Footer2';
import Footer1 from '../components/Footer/Footer1';
import { NavbarStyle } from './../components/Navbar/NavbarStyle';

export default function Gallery() {

  const defaultTheme= createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
  })
  return (
    <ThemeProvider theme={defaultTheme}>
    <div>
      <AppBar style={{ ...NavbarStyle.navbarStyle, boxShadow: '0px 3px 5px darkgrey' }}>
        <Toolbar>
          <Container>
            <NavbarMain />
          </Container>
        </Toolbar>
      </AppBar>
      <Box>
      <Container style={{ paddingTop: '130px' }}>
        <Grid container spacing={4} style={{ backgroundColor: theme.BODY_COLOR }}>
          <Grid item xs={12} sm={6} sx={{ background: theme.BRAND_COLOR2 }}>
            <Box paddingTop={1} textAlign='center' paddingBottom={2}>
              <Typography variant='h5' align='center' color='white'>Get Support for any Queries or Complaints</Typography>
              <Typography variant='body' align='center'  color='whitesmoke' >Committed to helping you meet all your Financial needs.</Typography>
            </Box>
            <Box sx={{ backgroundColor: '#fff' }} padding={5}>
              <Typography variant='caption'><LocationCityRounded /> Head Office</Typography>
              <Typography variant='body'>
                <address>
                  First Floor, Anjali Tower, Kavuvattam<br />
                  Cherpulassery, Palakkad - Kerala<br />
                  Pin: 679503
                </address>
              </Typography>
              <Typography variant='caption'><LocationCityRounded /> Corporate Office</Typography>
              <Typography variant='body'>
                <address>
                  GR Arcade, Mulankunnathukavu,<br/>
                  Konchery Rd, Thiroor, Thrissur,<br/>
                  Kerala Pin:680581
                </address>
              </Typography>
              <Typography variant='caption'><AccessTimeFilled /> Office Hours</Typography>
              <Typography variant='body'>
                <address>
                  Mon - Sat: 9.00am to 5.30pm<br/>
                  Sunday Holiday
                </address>
              </Typography>
              <Typography variant='caption'><LocalPhone /> Front Desk</Typography>
              <br />
              <Typography variant='body'>
                9072 600 700
              </Typography>
              <Box paddingRight={50}>
                <a href='' style={{ textTransform: 'capitalize' }}>info@vikasmoney.com</a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <br />
            <br />
            <br />
            <Box padding={3} sx={{ backgroundColor: 'white', width: '95%' }}>
              <Typography variant='caption'>*All fields are Mandatory</Typography>
              <Xtextfield label='Name' width='100%' />
              <Xtextfield label='Email Address' width='100%' />
              <Xtextfield label='Phone Number' width='100%' />
              <Xtextfield label='Subject' width='100%' />
              <Xtextfield label='Message' width='100%' />
              <Box paddingLeft='4px'>
                <Button style={{ backgroundColor: theme.BRAND_BUTTON2, color: theme.WHITE_TEXT, textTransform: 'capitalize' }}>Send A Message</Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      </Box>
      <div>
        <Footer1 />
        <Footer2 footerbg={theme.FOOTER_COLOR} />
      </div>
    </div>
    </ThemeProvider>
  );
}
