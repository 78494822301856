import { Typography, Button, Grid } from '@mui/material';
import React from 'react';
import { Box } from '@mui/material';

export default function Imgtext({
  direction,
  imagesrc,
  title,
  btnlabel,
  btndirection,
  padding,
  btnpadding,
  border,
  btnposition
}) {
  return (
    <div>
      <Box display="flex" flexDirection={direction} alignItems="center" padding={padding} borderBottom={border}>
        <img src={imagesrc} alt="Description of the image" />
        <Grid container direction="column"  >
          <Typography padding="5px 0 0 11px">{title}</Typography>
          <Button style={{justifyContent:btnposition,padding:btnpadding,textTransform: 'capitalize',}} >{btnlabel}</Button>
        </Grid>
      </Box>
    </div>
  );
}
