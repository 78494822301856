import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import slidev1 from '../images/slidev1.jpg';
import slidev2 from '../images/slidev2.jpg';
import slidev3 from '../images/slidev3.jpg';
import slidev4 from '../images/slidev4.jpg';
import vikasslider_3 from '../images/vikasslider_3.jpg';
import { theme } from '../../common utils/Colors';
import vikasslide1 from '../../pages/images/slides/vikasslide1.jpg'
function Xcarousel ({border}) {
  const images = [
    slidev4,vikasslide1,vikasslider_3
  ];

  return (
    <Carousel style={{border:border}}  >
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={image}
            alt={`Slide ${index}`}
          />
          
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Xcarousel ;