import React from 'react';
import { cardStyle } from './CardStyle';
import { Box, Button, Card, CardContent, CardMedia, Typography,Icon } from '@mui/material';
//import avatar from '../../assets/avatar.png';

function Cardlayout({imageSrc,title1,title2,cardHeight,textFont1,textFont2,cardWidth,style1,
    imgbgcolor,direction,cardcolor,buttontext,buttoncolor,bttextcolor,btradius,cardborder,
    colortx2,colortx1,btwidth,Btnheight,cardradius,btnposition,margin,iconComponent,btnfontsize,
    btnpaddingtop,cardbottomborder,btnleft,btnright,btnpaddingBottom,text2padding,txt2position,txt2weight}) {
    return (
        <div>
            <Card style={{...cardStyle.cardContainer,height:cardHeight,width:cardWidth , flexDirection:direction,
                backgroundColor:cardcolor,border:cardborder,
                borderRadius:cardradius,margin:margin,borderBottom:cardbottomborder}}>
                {imageSrc && (
                <Box style={{...cardStyle.imageView, backgroundColor:imgbgcolor,}}>
                    <CardMedia image={imageSrc} style={{...cardStyle.imageStyle} } />

                </Box>
                )}
                

                <Box style={cardStyle.ContentView}>
                    <CardContent  >
                        <div>
                        <Typography  style={{...cardStyle.typography1,fontSize:textFont1,fontStyle:style1,color:colortx1,}}>
                            {title1} 
                        </Typography>
                        </div>
                        <div>
                        <Typography  style={{...cardStyle.typography2,fontSize:textFont2,color:colortx2,padding:text2padding,justifyContent:txt2position,fontWeight:txt2weight}}>
                       
                           {title2}
                        </Typography>
                        </div>
                        <div>
                        <Button style={{...cardStyle.button1,backgroundColor:buttoncolor,color:bttextcolor,
                            borderRadius:btradius,width:btwidth,height:Btnheight,justifyContent:btnposition,fontSize:btnfontsize,
                            marginTop:btnpaddingtop,paddingLeft:btnleft,paddingRight:btnright,paddingBottom:btnpaddingBottom,textTransform: 'capitalize'}}> 
                                {buttontext}</Button>
                                </div>
                    </CardContent>
                </Box>

            </Card>

        </div>
    )
}

export default Cardlayout;