import React from 'react';
import { Grid, Typography,Button, Box } from '@mui/material';
import { theme } from '../../common utils/Colors';

const containerStyle = {
  borderTop: '2px solid #ddd',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 30,
  paddingBottom: 30,
};
function Footer1 () {
  return (
    
    <Grid container style={{...containerStyle,}}>
      <Typography align='center' padding={1}>Find your Nearest Branch. Visit your nearest branch today</Typography>
      <br/>
      <Button style={{backgroundColor:theme.BRAND_BUTTON,textTransform: 'capitalize',}} variant="contained" color="primary">Find Branch</Button>
    </Grid>
     
      
    
    
  );
}

export default Footer1;
