export const cardStyle = {
    cardContainer :{
        height:'150px',
        width:'400px',
        borderWidth:'1px',
        borderRadius:'0px',
        margin:'20px',
        display:'flex',
        padding:'10px',
        flexDirection: 'row',
        alignItems:'center',
    },
    imageView:{

        height:'100%',
        width:'40%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'#fff'
    
    },
    imageStyle :{
        height:'100px',
        width:'100px',
        position:'end',
        
    },
    ContentView:{
        height:'100%',
        width:'100%',  
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
       // backgroundColor:'#fff'

    },
    typography1:{
        fontSize:'30px',
        //marginBottom:'10px',
        //fontStyle:'italic',
        marginTop:'20px',
        //marginBottom:'0px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    typography2:{
        fontSize:'20px',
        //marginBottom:'10px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        
    },
    button1:{
        width:'70px'
    }
}