import React from 'react';
//import './App.css';
//import avatar from './assets/avatar.png'
//import CardLayout from '../src/components/Carousel3/CardLayout';
import Home from './components/Home';
import Services from './pages/services/Services.js'
import Goldloan from './pages/services/Goldloan'
import Personaloan from'./pages/services/Personaloan'
import Vehicleloan from'./pages/services/Vehicleloan'
import Businessloan from'./pages/services/Businessloan'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import Career from './pages/Career';
import Gallery from './pages/Gallery';
import Getintouch from'./pages/Getintouch'
import News from './pages/News'
import Downloads from './pages/Downloads';
import Mdsdesk from './pages/Mdsdesk';
import Viewall from './pages/Viewall';
import Makeanenquery from './pages/Makeanenquery';
import { createTheme } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import Consumerloan from './pages/services/Consumerlosn'
import Forex from './pages/services/Forex'
function App() {
  const defaultTheme= createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
  })

  return (
    <ThemeProvider theme={defaultTheme}>
    <div >
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/goldloan" element={<Goldloan/>} />
        <Route path="/personalloan" element={<Personaloan/>} />
        <Route path="/vehicleloan" element={<Vehicleloan/>} />
        <Route path="/businessloan" element={<Businessloan/>} />
        <Route path="/aboutus" element={<AboutUs/>} />
        <Route path="/career" element={<Career/>} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="/getintouch" element={<Getintouch/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/downloads" element={<Downloads/>} />  
        <Route path="/mdsdesk" element={<Mdsdesk/>} />
        <Route path="/viewall" element={<Viewall/>} />
        <Route path="/enquery" element={<Makeanenquery/>} />
        <Route path="/consumer" element={<Consumerloan/>}/>
        <Route path="/forex" element={<Forex/>}/>
      </Routes>
    </Router>
    </div>
    </ThemeProvider>
  );
}

export default App;