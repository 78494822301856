import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { theme } from '../../common utils/Colors';
import { Link } from 'react-router-dom';

const Xbutton = ({
  label,
  color,
  variant,
  onClick,
  startIcon,
  endIcon,
  disabled,
  height,
  width,
  radius,
  border,
  textcolor,
  imagesrc,
  imgheight,
  imgwidth,
  Direction,
  alignItems,
  display,
  padding,
  position,
  bottom,
  hoverButtonStyle,
  hoverTextColor,
  hoverIconColor,
  path,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    height: height,
    width: width,
    border: border,
    borderRadius: radius,
    color: isHovered ? hoverTextColor : textcolor,
    flexDirection: Direction,
    alignItems: alignItems,
    display: display,
    padding: padding,
    position: position,
    bottom: bottom,
  };

  const iconStyle = {
    height: imgheight,
    width: imgwidth,
    filter: isHovered ? `brightness(0) invert(1)` : 'initial',
  };

  return (
    <Link to={path}> {/* Replace "/your-link-here" with the actual link */}
      <Button
        style={buttonStyle}
        variant={variant}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          backgroundColor: theme.WHITE_BG,
          '&:hover': {
            backgroundColor: isHovered ? hoverButtonStyle : 'initial',
          },
        }}
      >
        <img src={imagesrc} alt="icon" style={iconStyle} />
        <span style={{ position: 'absolute', bottom: 0, textTransform: 'capitalize' }}>{label}</span>
      </Button>
    </Link>
  );
};

export default Xbutton;
