import React from 'react'
import Services from './Services'
import personalloan from '../images/personalloan.jpg'
import { Button } from 'react-bootstrap'
import { theme } from '../../common utils/Colors'
import Xaccordian from '../../components/accordian/Xaccordian'
export default function Goldloan() {
  return (
   
   <div>
<Services
imagsrc1={personalloan}
title1='Personal Loans'
title2='* Loan Amount - Upto 50000

* Interest Rates - It varies based on the credit history of the customer

* Loan Processing Time Period - It can be sanctioned between 1 day to 2 working days

* Tenure - 2 years

* Repayment - Weekly And Monthly options are Avilable

* Eligibility - The borrower should be a resident of India. Between 18 to 58 years of age. A Good CIBIL score needed.

*terms & conditions apply'
producttype='Personal Loan'

griditem={[<Xaccordian title='What is personal loan?' content="Gold loans are secured loans where the customer pledges the gold ornaments they hold as a collateral deposit against the money they are going to receive. Gold loans are usually taken for short term financial obligations." />,
        <Xaccordian title='How to apply personal loan?' content='Procedures included in the application for a gold loan are very simple. You have to first approach a lender, the lender will value your gold and calculate the maximum amount you can borrow. You will be required to fill a loan application for this and submit necessary documents such as copies of ID proofs and valid address proof. Once the value is calculated the money is instantly disbursed and you can use it for your intended purpose immediately.'/>,
     <Xaccordian title='Who can apply for personal loan?' content='Anybody over the age of 18 can apply for a gold loan provided he or she is the actual owner of the ornaments they present. The banks are not required to consider factors such as income, age, credit score, banking history, etc.'/>
      ]}


boxitem={<Button style={{backgroundColor:theme.BRAND_BUTTON}}>REQUEST A CALL BACK</Button>}


/>

    </div>
  )
}
