import React from 'react'
import Footer1 from '../components/Footer/Footer1'
import Footer2 from '../components/Footer/Footer2'
import Footer from '../components/Footer/Footer'
import { NavbarStyle } from './../components/Navbar/NavbarStyle'
import { Button, Container, Grid, Paper, Typography,Box, Icon, Link, createTheme, AppBar, Toolbar, ThemeProvider,  } from '@mui/material';
import NavbarMain from '../components/Navbar/NavbarMain'
import { theme } from '../common utils/Colors'
import career from'../pages/images/career.jpg'
import careerintro from'../pages/images/intro/careerintro.jpg'
import LocalPhone from '@mui/icons-material/';
import DoneIcon from '@mui/icons-material/Done';
import Imgtext from '../components/Imgtext'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { CareerStyle } from './CareerStyle'
export default function Career() {
  const defaultTheme= createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
  })
  return (
    <ThemeProvider theme={defaultTheme}>
    <div>
    <AppBar style={{...NavbarStyle.navbarStyle,boxShadow:"0px 3px 5px darkgrey",}}>
    <Toolbar>
    <Container >
       <NavbarMain/>
     </Container>
     </Toolbar>
  </AppBar>

  <Container>
      <Box style={{ position: 'relative' }}>
  <img src={career} alt="goldloan" style={{ width: '100%', height: 'auto' }} />
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      //backgroundColor: 'rgba(0, 95, 170, 0.7)', // Adjust the alpha value (0.7 in this example)
    }}
  ></div>
</Box>
</Container>
    <Container>
    <Grid style={{backgroundColor:theme.BODY_COLOR}} paddingTop='50px'>
    
     {/* banner image*/}
 <Grid container spacing={4} paddingTop={4}>
 
      <Grid item xs={12} sm={6} md={6} lg={6} >
        <Box paddingTop={4}>
      <img src={careerintro} alt="goldloan" style={{ width: '100%', height: 'auto',filter:'grayscale(100%)'}} />
      </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box paddingTop={4}>
      <Typography variant="h4" align='start' fontWeight="bold" >Be A Part Of Our Team</Typography>
       
        <Typography paddingTop={3} align='start'><DoneIcon style={{color:theme.BRAND_COLOR2}}/> Are you a great explorer in the financial sector?</Typography>
        <Typography paddingTop={3} align='start'><DoneIcon style={{color:theme.BRAND_COLOR2}}/> Are you focused on a successful career?</Typography>
        
        <Box display='flex' flexDirection='column'>
          
       <Button style={{justifyContent:'flex-start'}}> send resume </Button>
       <Button style={{justifyContent:'flex-start'}}> Career@vikasmoney.com</Button>
       </Box>
       </Box>
      </Grid>
      {/* Add more Grid items if needed */}
    </Grid>
    <Grid>
<Box padding={10}>
    <TableContainer >
      <Table style={CareerStyle.tabeleborder}>
        <TableHead >
          <TableRow  style={{backgroundColor:theme.BRAND_COLOR2,}} > 
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}} >Sl No</TableCell>
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}}>Resignation</TableCell>
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}}>Minimum Qualification</TableCell>
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}}>Minimum WorkExperience</TableCell>
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}}>Prefered age range</TableCell>
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}}>Salary range</TableCell>
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}}>Other</TableCell>
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}}>Remark</TableCell>
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}}>Location</TableCell>
            <TableCell  style={{...CareerStyle.tabeleborder,color:theme.WHITE_TEXT}}>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{backgroundColor:theme.WHITE_BG}} >
            <TableCell style={CareerStyle.tabeleborder}></TableCell>
            <TableCell style={CareerStyle.tabeleborder}></TableCell>
            <TableCell style={CareerStyle.tabeleborder}></TableCell>
            <TableCell >
           
            </TableCell>
            <TableCell  style={CareerStyle.tabeleborder}></TableCell>
            <TableCell style={CareerStyle.tabeleborder}></TableCell>
            <TableCell style={CareerStyle.tabeleborder}></TableCell>
            <TableCell style={CareerStyle.tabeleborder}></TableCell>
            <TableCell style={CareerStyle.tabeleborder}></TableCell>
            <TableCell style={CareerStyle.tabeleborder}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    </Grid>
</Grid>
</Container>

  

<Footer1/>
      <Footer2 footerbg={theme.FOOTER_COLOR}/>

</div>
</ThemeProvider>
  )
}
