import React from 'react';
import CardLayout from './Cards/CardLayout';
import { Button, Container, Grid, Paper, Typography, Box, createTheme, AppBar, Toolbar, Hidden } from '@mui/material';
import Footer2 from './Footer/Footer2';
import Footer1 from './Footer/Footer1';
import nbfc from './images/nbfc.png';
import credit from './images/credit.png';
import rbi from './images/rbi.png';
import Xcarousel from './Carousel/Xcarousel';
import vkmposter from './images/vkmposter.png';
import vkmwp from './images/vkmwp.jpg';
import { HomeStyle } from './HomeStyle';
import Xbutton from './Buttons/Xbutton';
import loan from './images/loan.png';
import vehicleloan from './images/vehicleloan.png';
import goldloan from './images/goldloan.png';
import Personalloan from './images/personalloan.png';
import LocalPhone from '@mui/icons-material/LocalPhoneOutlined';
import NavbarMain from './Navbar/NavbarMain';
import { NavbarStyle } from './Navbar/NavbarStyle';
import touch from './images/touch.png';
import Footer from './Footer/Footer';
import { theme } from '../common utils/Colors';
import Imgtext from './Imgtext';
import { ThemeProvider } from '@emotion/react';
import Multicarousel from './Carousel/Multicarousel';
import vikasads from '../pages/images/video/vikasads.mp4';
import playstore from '../pages/images/icons/playstore.png';
import { useNavigate } from 'react-router-dom';
import Sideicons from './Sideicons';
function Home() {
  const defaultTheme= createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
  })
  const Navigation=useNavigate()
  const handleClick = (event) => {
  };


  
  return (
    <ThemeProvider theme={defaultTheme}>
    <Box >  
      
      {/* Navbar start*/}
      <AppBar
      style={{
        ...NavbarStyle.navbarStyle,
        boxShadow: '0px 3px 5px darkgrey',
        
      }}
    >
      <Toolbar>
        <Container>
          <NavbarMain />
        </Container>
      </Toolbar>
    </AppBar>
    {/* Rest of your app */}
      {/* Navbar end*/}
      <br/>
      <div>
      </div>
      {/* <Grid>
      <Sideicons/>
      </Grid> */}
    <Container>
    
      <Paper elevation={0} style={{backgroundColor:theme.BODY_COLOR,paddingTop:'90px'}}  >
      
         {/* products start */}
         <Grid container spacing={2} >
      <Grid item xs={12} sm={8} md={8} lg={8}  >
        <Box paddingBottom={1} paddingRight='19px'paddingLeft='19px'  >
        <Xcarousel border={theme.BRAND_BORDER}/>
        </Box>
        
        {/* Enquiry button start */}
        <Box paddingRight='19px' paddingLeft='19px' >
        <Box style={{ paddingLeft:'19px',  display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', borderRadius: '5px',border:theme.BRAND_BORDER }}>
          <Typography style={{fontFamily:theme.FONT_FAMILY}}> <img
            src={touch} 
            alt="touch"/> Make an enquiry</Typography>
          <Button style={{ backgroundColor: theme.BRAND_BUTTON, color: 'white',textTransform: 'capitalize',width:'90px' }} onClick={()=>Navigation("/getintouch")}>
            Enquiry</Button>
        </Box>
        </Box>
        {/* Enquiry button end */}
      </Grid>
    
     
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Grid container spacing={2} padding='0 19px 0 19px'>  
          <Grid item xs={6} sm={6} md={6} lg={6}>
          <Grid>
          
        <Xbutton
          label='Gold Loan'
          border='2px solid #ddd'
         width='100%'
         height='140px'
         radius='10px'
         textcolor='black'
         imgheight='50px'
         imgwidth='50px'
         imagesrc={goldloan}
         hoverButtonStyle={theme.BRAND_BUTTON}
         hoverTextColor="white"
         hoverIconColor="white" 
         path="/goldloan"
          />
          
           </Grid>
          </Grid>
          <Grid item  xs={6} sm={6} md={6} lg={6}>
          <Grid>
        <Xbutton
          label='Personal loan'
          border='2px solid #ddd'
         width='100%'
         height='140px'
         radius='10px'
         textcolor='black'
         Direction='column'
         imgheight='50px'
         imgwidth='50px'
         imagesrc={Personalloan}
         hoverButtonStyle={theme.BRAND_BUTTON}
         hoverTextColor="white"
         hoverIconColor="white"
         path="/personalloan"
          />
           </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
          <Grid>
        <Xbutton
          label='Consumer Loan'
          border='2px solid #ddd'
          width='100%'
          height='140px'
          radius='10px'
          textcolor='black'
          imgheight='50px'
          imgwidth='50px'
          imagesrc={loan}
          hoverButtonStyle={theme.BRAND_BUTTON}
          hoverTextColor="white"
          hoverIconColor="white"
          path="/businessloan"
          />
           </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
          <Grid>
        <Xbutton
          label='Vehicle Loan'
          border='2px solid #ddd'
          width='100%'
          height='140px'
          radius='10px'
          textcolor='black'
          imgheight='50px'
          imgwidth='50px'
          imagesrc={vehicleloan}
          hoverButtonStyle={theme.BRAND_BUTTON}
          hoverTextColor="white"
          hoverIconColor="white"
          path="/vehicleloan"
          />
           </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={2} justifyContent="center" alignItems="center" padding='10px 19px 0 19px' >
                <Typography  fontWeight= 'bold' align="center" style={{fontFamily:theme.FONT_FAMILY}}>Products for your needs</Typography >
                <Button style={{...HomeStyle.homescreenbuttons,backgroundColor: theme.BRAND_BUTTON}} onClick={()=>Navigation("/viewall")}>View all</Button>
              
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>     
   {/* products end End */}

   <Box padding='10px 19px 0 19px'>
   <Grid container spacing={2}>
  <Grid item xs={12} sm={9} md={9} lg={9}>
    <div style={{...HomeStyle.videoContainerStyle,}}>
    <Hidden lgDown> 
      <Box sx={{bgcolor:'black',}}  >
    <video style={{display:'flex'}}  height="253px"  controls>
        <source src={vikasads} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </Box>
      </Hidden>
      {/* <img
        src={achivements}
        alt="slide4"
        style={{
          width: '100%',
          height: '253px',
          maxHeight: '500px', 
        }}
      /> */}
      {/* achievwments start */}
      
      <Grid container style={HomeStyle.overlayTextStyle} spacing={5}>
       <Box>
      
       </Box>
        <Hidden lgDown> 
        
          <Grid item style={{...HomeStyle.textonvideo,}}  xs={12} sm={4} md={4}>
            <Container style={{paddingLeft:'40px'}}>
              <Box style={{ border:theme.BRAND_BORDER}}>
              <br />
            <Typography  color='#fff' >36 years trusted</Typography>
            
            <br />
            </Box>
            </Container>
           
          </Grid>

          <Grid item style={{...HomeStyle.textonvideo,}} xs={12} sm={4} md={4} >
            <Box  style={{ border:theme.BRAND_BORDER}}>
            <br />
            <Typography  color='#fff'>2000+ Monthly Disbursement</Typography>
            <br />
            </Box>
          </Grid>
          <Grid item style={{...HomeStyle.textonvideo,}}  xs={12} sm={4} md={4}>
            <Box  style={{ border:theme.BRAND_BORDER}}>
            <br />
            <Typography borderRight='1px solid black 'color='#fff'>100000+ Happy Customers</Typography>
            <br />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  </Grid>
{/* achievwments start */}

    
 
  {/* Contact card start */}
<Grid item xs={12} sm={3} md={3} lg={3} >
  <CardLayout 
        title1='For any assistance please call our customer care number'
        textFont1='15px'
        title2={[<LocalPhone style={{color:'green'}}/>,'9072600700']}
        cardHeight='250px'
        cardWidth='100%'
        textFont2='25px'
        txt2weight='bold'
        colortx2={theme.BRAND_BUTTON}
        txt2position='flex-start'
        buttontext='CONTACT US'
        buttoncolor='green'
        btradius='20px'
        bttextcolor='white'
        //cardcolor='#EBD4C6'
        cardborder={theme.BRAND_BORDER}
        cardradius='10px'
        iconComponent={<LocalPhone/>}
        btnfontsize='8px'
        btwidth='90px'
        btnpaddingtop='10px'
      />

</Grid>
</Grid>
{/* Contact card end */}
  <br/> 
  {/* newfetures start */}
    <Grid container spacing={4}>
      <Grid item xs={12} sm={4}>
        <Box md={4}>
          <Typography variant="h6" component="h1" gutterBottom style={{...HomeStyle.textinformationsStyle,}}>
            New Features
          </Typography>
          <Typography variant="body1" paragraph >
            Explore
          </Typography>  
          <img
            src={vkmwp}
            alt="Description of the image"
            style={{ maxWidth: '100%', height: 'auto', display: 'block' }}
          />   
        </Box>
      </Grid>
{/* newfetures end */}
{/* informative start  */}
      <Grid item xs={12} sm={4} >
        <Typography variant="h6" component="h1" gutterBottom style={{...HomeStyle.textinformationsStyle,}}>
          informative
        </Typography>
        <Typography variant="body1" paragraph >
          Nbfc finance News & more
        </Typography>
        <Paper>
        <Box >
            <Imgtext
            title='How to build a robust retirement corpus with NPS?'
            imagesrc={rbi}
            border='2px solid #ddd'
            btnlabel='Read More'
            btnposition="flex-end"
            padding="0 0 5px 0"
            btnpadding="43px 5px 0 0"
            />
            <Imgtext
            title='How a change in RBI’s repo rate impacts your home loan EMIs?'
            imagesrc={nbfc}
            direction='row-reverse'
            border='2px solid #ddd'
            btnlabel='Read More'
            btnposition="flex-start"
            padding="2px 0 1px 0"
            btnpadding="27px 0 0 10px"
            />
           <Imgtext
            title='Why keeping your Credit Card active is important'
            imagesrc={credit}
            btnlabel='Read More'
            btndirection='column'
            btnposition="flex-end"
            padding="3px 0 0 0"
            btnpadding="44px 5px 0 0"
            />
  <div style={{ textAlign: 'center' ,paddingBottom:'21px'}}>
      <Button style={HomeStyle.cardbuttonstyle} onClick={()=>Navigation("/news")} >Read All</Button>
  </div>
</Box>
</Paper>
      </Grid>
      {/* informative end*/}
 {/* vikaspay start*/}
      <Grid item xs={12} sm={4}>
        <Box md={4}>
          <Typography variant="h6" component="h1" gutterBottom style={{...HomeStyle.textinformationsStyle,}}>
            Vikas payment App
          </Typography>
          <Typography variant="body1" paragraph >
           Pay your repayment easly
           <Button href='https://play.google.com/store/apps/details?id=com.vikasvpay' color="primary">
        <img src={playstore} alt="Image Alt Text" style={{width:'100%',height:'40px'}}/>
      </Button>
          </Typography>
          <img
            src={vkmposter} 
            alt="Description of the image"
            style={{ maxWidth: '100%', height: 'auto', }}
          />

        </Box>
      </Grid>
    </Grid>
    {/* vikaspay end*/}
    </Box>
      </Paper>
    </Container>


{/* Customer review card start */}

<Grid container >
      <Container>
        <Box paddingBottom={4} paddingTop={4} >
          <Typography fontWeight= 'bold' >What our customers are saying about us</Typography>
        </Box>
        
      </Container>
      
    </Grid>
    </Box>
    <Box >
     
    <Box paddingLeft={3} >
    <Multicarousel 
  
            />
   {/* <Box style={{...HomeStyle.reviewcardbuttonposition,}}>
        <Button style={{...HomeStyle.reviewcardbutton,}}> View All</Button>
        </Box > */}

</Box>
        {/* Customer review card end */}
        <br/>
        <br/>
    {/* footer start */}
      <Footer1/>
      <Footer2 footerbg={theme.FOOTER_COLOR}/>
      {/* footer end */}
    </Box>
    
    </ThemeProvider>






  );
}

export default Home;
