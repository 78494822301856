import React from 'react'
import Footer1 from '../components/Footer/Footer1'
import Footer2 from '../components/Footer/Footer2'
import Footer from '../components/Footer/Footer'
import { NavbarStyle } from './../components/Navbar/NavbarStyle'
import { Button, Container, Grid, Paper, Typography,Box, Icon, Link, createTheme, AppBar, Toolbar, IconButton, ThemeProvider,  } from '@mui/material';
import NavbarMain from '../components/Navbar/NavbarMain'
import { theme } from '../common utils/Colors'
import Cardlayout from '../components/Cards/CardLayout'
import Imgtext from '../components/Imgtext'
import rbi from '../components/images/rbi.png'
import nbfc from '../components/images/nbfc.png'
import credit from '../components/images/credit.png'
import { AccessTime, AccessTimeOutlined, Facebook, Instagram, TodayOutlined, WhatsApp } from '@mui/icons-material'
export default function Career() {
  const defaultTheme= createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
  })
  return (
    <ThemeProvider theme={defaultTheme}>
    <div>
    <AppBar style={{...NavbarStyle.navbarStyle,boxShadow:"0px 3px 5px darkgrey",}}>
    <Toolbar>
    <Container >
       <NavbarMain/>
     </Container>
     </Toolbar>
  </AppBar>
<Container>
  <Grid container spacing={2} paddingTop={15} paddingBottom={2} style={{ backgroundColor: theme.BODY_COLOR }}>

<Grid item xs={12} sm={8} md={8} lg={8} >
<Typography variant='h5'>How to build a robust retirement corpus with NPS?</Typography>
<Box display='flex' justifyContent='space-between' paddingTop={2}>
<Typography varient='capation' ><AccessTimeOutlined style={{padding:'2px',paddingBottom:'5px'}}/>5 min</Typography>
<Typography paddingLeft={5} varient='capation'><TodayOutlined style={{padding:'2px',paddingBottom:'5px'}}/>oct,10,2023</Typography>
<Box >
  <IconButton><Facebook/></IconButton>
  <IconButton><Instagram/></IconButton>
  <IconButton><WhatsApp/></IconButton>
  </Box>

</Box>
<img src={rbi} alt='' width='100%' height='300px' />
</Grid>
<Grid item xs={12} sm={4} md={4} lg={4}>

<Typography fontWeight='bold'>Trending</Typography>
<Box sx={{backgroundColor:'#fff'}}>
<Imgtext
            title='How to build a robust retirement corpus with NPS?'
            imagesrc={rbi}
            border='2px solid #ddd'
            btnlabel='Read More'
            btnposition="flex-end"
            padding="0 0 5px 0"
            btnpadding="43px 5px 0 0"
            />
              <Imgtext
            title='How a change in RBI’s repo rate impacts your home loan EMIs?'
            imagesrc={nbfc}
            direction='row-reverse'
            border='2px solid #ddd'
            btnlabel='Read More'
            btnposition="flex-start"
            padding="2px 0 1px 0"
            btnpadding="27px 0 0 10px"
            />
             <Imgtext
            title='Why keeping your Credit Card active is important'
            imagesrc={credit}
            btnlabel='Read More'
            btndirection='column'
            btnposition="flex-end"
            padding="3px 0 0 0"
            btnpadding="44px 5px 0 0"
            />
            </Box>
</Grid>
</Grid>
</Container>
<div>
<Footer1/>
      <Footer2 footerbg={theme.FOOTER_COLOR}/>
     
</div>
</div>
</ThemeProvider>
  )
}
