import React from 'react'
import Footer1 from '../components/Footer/Footer1'
import Footer2 from '../components/Footer/Footer2'
import Footer from '../components/Footer/Footer'
import { NavbarStyle } from './../components/Navbar/NavbarStyle'
import { Button, Container, Grid, Paper, Typography,Box, Icon, Link, createTheme, AppBar, Toolbar,  } from '@mui/material';
import NavbarMain from '../components/Navbar/NavbarMain'
import { theme } from '../common utils/Colors'
export default function Makeanenquery() {
  return (
    <div>
    <AppBar style={{...NavbarStyle.navbarStyle,boxShadow:"0px 3px 5px darkgrey",}}>
    <Toolbar>
    <Container >
       <NavbarMain/>
     </Container>
     </Toolbar>
  </AppBar>

<div>
<Footer1/>
      <Footer2 footerbg={theme.FOOTER_COLOR}/>
      <Footer footerbg={theme.FOOTER_COLOR}/>
</div>
</div>
  )
}
