import { Container, Grid, Typography,AppBar,Toolbar,Box } from '@mui/material'
import React from 'react'
import aboutus from './images/aboutus.jpg'
import NavbarMain from '../components/Navbar/NavbarMain'
import { NavbarStyle } from './../components/Navbar/NavbarStyle'
import Footer2 from '../components/Footer/Footer2'
import Footer from '../components/Footer/Footer'
import { theme } from '../common utils/Colors'
import Footer1 from '../components/Footer/Footer1'
import about from './images/about.jpg'
import vikasmd from './images/vikasmd.jpg'
export default function Mdsdesk() {
  return (
<div>
<AppBar style={{...NavbarStyle.navbarStyle,boxShadow:"0px 3px 5px darkgrey",}}>
        <Toolbar>
        <Container >
           <NavbarMain/>
         </Container>
         </Toolbar>
      </AppBar>
      <Container>
      <Box style={{ position: 'relative' }}>
  <img src={aboutus} alt="goldloan" style={{ width: '100%', height: 'auto' }} />
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      //backgroundColor: 'rgba(0, 95, 170, 0.7)', // Adjust the alpha value (0.7 in this example)
    }}
  ></div>
</Box>
</Container>
    <Container>
    <Grid style={{backgroundColor:theme.BODY_COLOR}} paddingTop='50px'>
    
     {/* banner image*/}
 
     <Typography variant="h4" align='center' fontWeight="bold" >The future is inspired by the past</Typography>
     <Typography align='center'>Few Words From My Experience</Typography>
 <Grid container spacing={2} paddingTop={4}>
 
      <Grid item xs={12} sm={2} md={2} lg={2} >
        <Box paddingTop={4}>
      <img src={vikasmd} alt="goldloan" style={{ width: '100%', height: 'auto' }} />
      <Typography  variant="h5" align='center' fontWeight="bold">A Pradeep Menon</Typography>
      <Typography align='center'   style={{color:theme.BRAND_COLOR2}} >MANAGING DIRECTOR</Typography>
      </Box>
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={10}>
       
        <Typography paddingTop={3}>When I look two decades back at the road from where 
        we came to serve the nation and it’s people then I wonder myself how we achieved such a landmark journey in a country of 1.3 billion. Our seed of dreams germinated into a gigantic tree with countless fruits of customer happiness and loyalty. Today, our company is the reason behind lakhs of smiling faces that partnered with us to transform their dreams into reality. During the course of it’s journey, Vikas Money Ltd. has been bestowed with numerous awards and laurels, but I personally believe that being the reason behind the happiness and success of lakhs of customers is the most coveted feather to our cap.
</Typography>
          <Typography paddingTop={3} paddingBottom={3}>Vikas Money Ltd. is currently one of the fastest growing NBFCs in the country and the credit for it’s success goes to the business ethics and values that we cultivated and nurtured since the inception of the company. We, together as a family, as a team pursued this journey patiently,
           step by step and always kept the interests of our community at the centre.</Typography>
           
           <Typography paddingTop={3} paddingBottom={3}>We transformed our businesses as per the needs and aspirations of people; be it starting my first firm at Cherpulassery in the year 1997 or moving one step further in the year 2000. We always adapted to the changing demands of the people. When we realized about the challenges people were facing in terms of gold loan and tedious disbursal documentations, we decided to expand the branches of first 
           firm which provided a hassle-free and extremely customer-friendly atmosphere.</Typography>
           
           <Typography paddingTop={3} paddingBottom={3}>We achieved a major milestone in the year 2012, when we acquired and diversified out credit business from gold to the vehicle loan sector. After realizing the boom in Foreign Exchange Operations in the country, we opened an
           exclusive Full Fledged Money Changer (FFMC) business company at Kavuvattom.</Typography>
           
           <Typography paddingTop={3} paddingBottom={3}>We changed the name of the company to Vikas Money Limited to establish an umbrella name for a diverse range of financial assistance and investment services offered to the customers. We are now expanding the branches to other zones in Kerala to benefit the
            maximum population of the state with innovative monetary solutions.</Typography>
           
           <Typography paddingTop={3} paddingBottom={3}>I personally believe that 21st century would belong to India and it is imperative for our fellow Indians residing in remotest of places to reap the modern banking benefits of the highest standards. That is the undivided vision of our company; to equip our Gramin Bharat and Nari Shakti (Women Empowerment) with wings that could dare them reaching for the sky with fullest potential. That is us,
            aspiring to become the shining crown of our New India…”</Typography>
      </Grid>
      {/* Add more Grid items if needed */}
    </Grid>




</Grid>
</Container>
<Footer1 />
<Footer2 footerbg={theme.FOOTER_COLOR}/>

</div>
  )
}

  
