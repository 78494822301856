import React from 'react'
import Services from './Services'
import businessloan from '../images/businessloan.jpg'
import Xaccordian from '../../components/accordian/Xaccordian'
import { Button, Container } from 'react-bootstrap'
import { theme } from '../../common utils/Colors'
export default function Consumerloan() {
  return (
   
   <div>
<Services
imagsrc1={businessloan}
title1='Explore And Apply Now'
title2='A consumer loan is a type of financial product provided by banks, credit unions, or other lending institutions to individuals for personal use. These loans are intended to help consumers finance various personal expenses, such as buying a car, paying for education, consolidating debt, covering medical bills, making home improvements, or funding a vacation. Consumer loans are typically unsecured, meaning they do not require collateral (like a house or a car) to secure the loan. Instead, the lender assesses the borrowers creditworthiness to determine whether to approve the loan and at what interest rate.'
producttype='Business Loan'

griditem={[<Xaccordian title='What is consumer loan?' content="Gold loans are secured loans where the customer pledges the gold ornaments they hold as a collateral deposit against the money they are going to receive. Gold loans are usually taken for short term financial obligations." />,
        <Xaccordian title='How to apply business loan?' content='Procedures included in the application for a gold loan are very simple. You have to first approach a lender, the lender will value your gold and calculate the maximum amount you can borrow. You will be required to fill a loan application for this and submit necessary documents such as copies of ID proofs and valid address proof. Once the value is calculated the money is instantly disbursed and you can use it for your intended purpose immediately.'/>,
     <Xaccordian title='Who can apply for business loan?' content='Anybody over the age of 18 can apply for a gold loan provided he or she is the actual owner of the ornaments they present. The banks are not required to consider factors such as income, age, credit score, banking history, etc.'/>
      ]}


boxitem={<Button style={{backgroundColor:theme.BRAND_BUTTON}}>REQUEST A CALL BACK</Button>}


/>

    </div>
  )
}
