import { theme } from "../../common utils/Colors";

export const accordiaStyle = {


    background:{
        backgroundColor:theme.BRAND_BUTTON,
        color:'white',
        
    },


}