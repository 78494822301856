import { Container, Grid, Typography,AppBar,Toolbar,Box, createTheme } from '@mui/material'
import React from 'react'
import aboutus from './images/aboutus.jpg'
import NavbarMain from '../components/Navbar/NavbarMain'
import { NavbarStyle } from './../components/Navbar/NavbarStyle'
import Footer2 from '../components/Footer/Footer2'
import Footer from '../components/Footer/Footer'
import { theme } from '../common utils/Colors'
import Footer1 from '../components/Footer/Footer1'
import about from './images/about.jpg'
import { ThemeProvider } from 'styled-components'
export default function AboutUs() {
  const defaultTheme= createTheme({
    typography: {
      fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
  })

  return (
    <ThemeProvider theme={defaultTheme}>
<div>
 
<AppBar style={{...NavbarStyle.navbarStyle,boxShadow:"0px 3px 5px darkgrey",}}>
        <Toolbar>
        <Container >
           <NavbarMain/>
         </Container>
         </Toolbar>
      </AppBar>
      <Box style={{ position: 'relative' }}>
  <img src={about} alt="goldloan" style={{ width: '100%', height: '200px' }} />
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 95, 170, 0.7)', // Adjust the alpha value (0.7 in this example)
    }}
  ></div>
</Box>
    <Container>
    <Grid style={{backgroundColor:theme.BODY_COLOR}} paddingTop='50px'>
    
     {/* banner image*/}
 
     <Typography variant="h4" align='center' fontWeight="bold" >Known For Trust, Honesty & Customer Support</Typography>
 <Grid container spacing={2} paddingTop={4}>
 
      <Grid item xs={12} sm={6} md={6} lg={6}>
      <img src={about} alt="goldloan" style={{ width: '100%', height: 'auto' }} />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
       
        <Typography paddingTop={3}>What are the new ideas that the dynamic leadership of Vikas Money Limited would like to introduce to serve this great nation and it’s people? How Vikas Money Limited would like to define itself in a country which has more than 9,000 NBFCs registered to serve it’s people? How we are going to define our role and contribute in the galloping 
          financial sector of one of the fastest developing economies of the world?.</Typography>
          <Typography paddingTop={3} paddingBottom={3}>Our relationship managers reach every corner of the rural and semi-rural outskirts and disburse the loans with simplest of formalities. Vikas Money Limited is consistently working over sound and transparent business model to cherish the long-term interests of our stakeholders and fulfilling it’s share
             of making India a financial powerhouse of the future.</Typography>
      </Grid>
      {/* Add more Grid items if needed */}
    </Grid>



</Grid>
</Container>
<Footer1 />
<Footer2 footerbg={theme.FOOTER_COLOR}/>
</div>
</ThemeProvider>
  )
}
