import React from 'react'
import Footer1 from '../components/Footer/Footer1'
import Footer2 from '../components/Footer/Footer2'
import Footer from '../components/Footer/Footer'
import { NavbarStyle } from './../components/Navbar/NavbarStyle'
import { Button, Container, Grid, Paper, Typography,Box, Icon, Link, createTheme, AppBar, Toolbar,  } from '@mui/material';
import NavbarMain from '../components/Navbar/NavbarMain'
import { theme } from '../common utils/Colors'
import Xbutton from '../components/Buttons/Xbutton'
import goldloan from'../components/images/goldloan.png'
import personalloan from'../components/images/personalloan.png'
import vehicleloan from'../components/images/vehicleloan.png'
import loan from'../components/images/loan.png'
import Xaccordian from '../components/accordian/Xaccordian'
export default function Viewall() {
  return (
    <div>
    <AppBar style={{...NavbarStyle.navbarStyle,boxShadow:"0px 3px 5px darkgrey",}}>
    <Toolbar>
    <Container >
       <NavbarMain/>
     </Container>
     </Toolbar>
  </AppBar>
  <Container>
    <Box paddingLeft='19px' paddingRight='19px'  paddingTop={15}  sx={{backgroundColor:theme.BODY_COLOR}}>
    <Grid>
  <Typography fontWeight= 'bold' >Explore our suite of customized solutions for every financial need.</Typography>
  </Grid>
  <Grid container spacing={2}paddingTop={3}  paddingBottom={3}>
    
  <Grid item xs={12} sm={3}>
    <Xbutton
      label='Gold Loan'
      border='2px solid #ddd'
      width='100%'
      height='140px'
      radius='10px'
      textcolor='black'
      imgheight='50px'
      imgwidth='50px'
      imagesrc={goldloan}
      hoverButtonStyle={theme.BRAND_BUTTON}
      hoverTextColor="white"
      hoverIconColor="white" 
      path="/goldloan"
    />
  </Grid>
  <Grid item xs={12} sm={3}>
    <Xbutton
      label='Personal loan'
      border='2px solid #ddd'
      width='100%'
      height='140px'
      radius='10px'
      textcolor='black'
      Direction='column'
      imgheight='50px'
      imgwidth='50px'
      imagesrc={personalloan}
      hoverButtonStyle={theme.BRAND_BUTTON}
      hoverTextColor="white"
      hoverIconColor="white"
      path="/personalloan"
    />
  </Grid>
  <Grid item xs={12} sm={3}>
    <Xbutton
      label='Consumer Loan'
      border='2px solid #ddd'
      width='100%'
      height='140px'
      radius='10px'
      textcolor='black'
      imgheight='50px'
      imgwidth='50px'
      imagesrc={loan}
      hoverButtonStyle={theme.BRAND_BUTTON}
      hoverTextColor="white"
      hoverIconColor="white"
      path="/consumer"
    />
  </Grid>
  <Grid item xs={12} sm={3}>
    <Xbutton
      label='Vehicle Loan'
      border='2px solid #ddd'
      width='100%'
      height='140px'
      radius='10px'
      textcolor='black'
      imgheight='50px'
      imgwidth='50px'
      imagesrc={vehicleloan}
      hoverButtonStyle={theme.BRAND_BUTTON}
      hoverTextColor="white"
      hoverIconColor="white"
      path="/vehicleloan"
    />
  </Grid>
</Grid>
<Grid container spacing={2}  paddingBottom={3}>
  <Grid item xs={12} sm={3}>
    <Xbutton
      label='Business Loan'
      border='2px solid #ddd'
      width='100%'
      height='140px'
      radius='10px'
      textcolor='black'
      imgheight='50px'
      imgwidth='50px'
      imagesrc={goldloan}
      hoverButtonStyle={theme.BRAND_BUTTON}
      hoverTextColor="white"
      hoverIconColor="white" 
      path="/businessloan"
    />
  </Grid>
  <Grid item xs={12} sm={3}>
    <Xbutton
      label='Forex'
      border='2px solid #ddd'
      width='100%'
      height='140px'
      radius='10px'
      textcolor='black'
      Direction='column'
      imgheight='50px'
      imgwidth='50px'
      imagesrc={personalloan}
      hoverButtonStyle={theme.BRAND_BUTTON}
      hoverTextColor="white"
      hoverIconColor="white"
      path="/forex"
    />
  </Grid>
  <Grid item xs={12} sm={3}>
    
  </Grid>
  <Grid item xs={12} sm={3}>
    
  </Grid>
</Grid>

  <Box align='center'>
  <Grid container spacing={2}  paddingBottom={3}>
    <Grid item xs={12} sm={6} md={6}>
<Xaccordian title='What is gold loan?' content="Gold loans are secured loans where the customer pledges the gold ornaments they hold as a collateral deposit against the money they are going to receive. Gold loans are usually taken for short term financial obligations." />
        <Xaccordian title='What is personalloan loan?' content='Procedures included in the application for a gold loan are very simple. You have to first approach a lender, the lender will value your gold and calculate the maximum amount you can borrow. You will be required to fill a loan application for this and submit necessary documents such as copies of ID proofs and valid address proof. Once the value is calculated the money is instantly disbursed and you can use it for your intended purpose immediately.'/>
     <Xaccordian title='What is consumer loan loan?' content='Anybody over the age of 18 can apply for a gold loan provided he or she is the actual owner of the ornaments they present. The banks are not required to consider factors such as income, age, credit score, banking history, etc.'/>
     </Grid>
     <Grid item xs={12} sm={6} md={6}>
     <Xaccordian title='What is business loan loan?' content="Gold loans are secured loans where the customer pledges the gold ornaments they hold as a collateral deposit against the money they are going to receive. Gold loans are usually taken for short term financial obligations." />
        <Xaccordian title='What is vehicle loan loan?' content='Procedures included in the application for a gold loan are very simple. You have to first approach a lender, the lender will value your gold and calculate the maximum amount you can borrow. You will be required to fill a loan application for this and submit necessary documents such as copies of ID proofs and valid address proof. Once the value is calculated the money is instantly disbursed and you can use it for your intended purpose immediately.'/>
     </Grid>
     </Grid>
     </Box>
     
     </Box>
</Container>



<div>
<Footer1/>
      <Footer2 footerbg={theme.FOOTER_COLOR}/>
   
</div>
</div>
  )
}
