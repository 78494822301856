import React from 'react'
import Services from './Services'
import goldloan from '../images/goldloan.jpg'
import money from '../images/icons/money.png'
import moneybox from '../images/icons/money-box.png'
import cash from '../images/icons/cash.png'
import NavbarMain from '../../components/Navbar/NavbarMain'
import Xaccordian from '../../components/accordian/Xaccordian'
import Xbutton from '../../components/Buttons/Xbutton'
import { Button, Container } from 'react-bootstrap'
import { theme } from '../../common utils/Colors'
export default function Goldloan() {
  return (
   
   <div>
   
<Services
imagsrc1={goldloan}
title1='Future Of The Gold Loans'
title2='Traditionally, in India gold has been considered as auspicious and it symbolizes femininity, prosperity, good health. Gold in Kerala has its cultural and sociological roots and transcend to form an integral part of Malayalis daily life. People consider it as an 
investment or to show their prosperity in order to garner higher social status. According to a Reserve Bank of India’s (RBI) report in 2022, Indian households are having a stock pile of more than 27,000 tonnes of Gold which is almost 14% of the total world’s gold.'
producttype='Gold Loan'

griditem={[<Xaccordian title='What is gold loan' content="Gold loans are secured loans where the customer pledges the gold ornaments they hold as a collateral deposit against the money they are going to receive. Gold loans are usually taken for short term financial obligations." />,
        <Xaccordian title='How to apply gold loan' content='Procedures included in the application for a gold loan are very simple. You have to first approach a lender, the lender will value your gold and calculate the maximum amount you can borrow. You will be required to fill a loan application for this and submit necessary documents such as copies of ID proofs and valid address proof. Once the value is calculated the money is instantly disbursed and you can use it for your intended purpose immediately.'/>,
     <Xaccordian title='Who can apply for gold loan' content='Anybody over the age of 18 can apply for a gold loan provided he or she is the actual owner of the ornaments they present. The banks are not required to consider factors such as income, age, credit score, banking history, etc.'/>
      ]}


boxitem={<Button style={{backgroundColor:theme.BRAND_BUTTON,textTransform: 'capitalize',}}>Request a call back</Button>}

/>

    </div>
  )
}
