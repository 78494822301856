export const Footer2Style = {

    footertitle:{
       color:'black',
       paddingTop:'10px',
       //display: 'flex',
       //justifyContent:'flex-start'
    },

    listbutton:{
       color:'black',
       textTransform: 'capitalize',
       height:'0',
      // width:'10%'
   },
      button1Style : {
         color: 'black',
         height: '40px',
         width:'100%', 
         borderColor: 'gray',
        // Adjust the height as needed
       },
   


}