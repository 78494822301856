import React from 'react';
import Navbar from './Navbar';
import vikaslogo2 from '../images/Vikaslogo2.png';
import whatsappImage from '../images/whatsappImage.png';

function NavbarMain() {
  return (
    <div>
        <Navbar imageSrc={vikaslogo2} Paper1='English' sideBox2Button='Apply Now'
        whatsappImage={whatsappImage} paymentcolor='#71BF45' btnbgc= '#71BF45'/>
       
    </div>
  )
}

export default NavbarMain;

