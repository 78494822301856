export const NavbarStyle = {
    navbarStyle: {
        height: '100px',
        width: '100%',
        backgroundColor: '#fff',
        margin: '0px',
        display: 'flex',
        elevation:'10px',
        //position:"fixed",
        //top:0
    },
    imageBoxStyle: {
        height: '100%',
        width: '15%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        //backgroundColor:'lightgreen',
        //marginRight:'10px',

    },
    imageStyle: {
        height: 'auto',
        maxWidth: '140px',
        paddingToP:'',
        marginRight: '0px',

    },
    sideBox: {
        width: '85%', height: '85%',
        //backgroundColor:'#ddd',

    },
    sideBox1: {
        width: '109%', height: '45%',
        //backgroundColor: 'green',
        display: 'flex',
        justifyContent:'space-between',

    },
    sideBox1First: {
       // backgroundColor:'red',
        //height: '100%', width: '95%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        paddingLeft: '300px',
    },
    sidebox1button:{
        textTransform: 'capitalize',
        color:'black',
    },
    sideBox1Last: {
        height: '100%', width: '25%',
       // backgroundColor:'whitesmoke',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
    },
    sideBox2: {
        borderRadius: '50px 0px 0px 0px',
        color:'#fff',
        height: '70%',
        width:'107%',
        backgroundColor: '#00668F',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'

    },
    sideBox21:{
        marginLeft:'30px',
               
    },
    sidebox21button:{
        textTransform: 'capitalize',
        color:'#fff',
        

    },
    sidebox21button2:{
        textTransform: 'capitalize',
        color:'black',
        display: 'flex',
        justifyContent:'flex-start',
        backgroundColor: 'transparent',
        width:'100%',
        borderRadius:0
    },
    sideBox22:{
        display:'flex',
        justifyContent:'end',
       // marginRight:'1px',

    },
    sideBox2Link: {
        cursor: 'pointer',
        textDecoration: 'none',
        textTransform: 'capitalize'
    },
    sideBox2Login:{
         backgroundColor:'#71BF45',
         borderRadius:0,
         textTransform: 'capitalize',
    },
    menuIconStyle:{
        fontSize:'50px',
        color:'black'
    },
    notificationBellStyle:{
       height:'20px', width:'20px',
       margin:'2px',

    },
    sideBoxlg:{
        display:'flex',
        alignItems:'center',
        justifyContent:'end',
    },
    notificationBellStylelg:{
        height:'20px', width:'20px',
        margin:'5px'

    },
    menubuttonhover:{

    }
}