import { Row } from "react-bootstrap";

export const HomeStyle = {


CarouselButton:{
 backgroundColor:"#fff",
 display: 'flex',
 justifyContent: 'space-between',
 border:'1px solid #ddd',
 padding:'10px',
 paddingTop:'-2px'
 

},

buttons123:{
    color:'black',
    textTransform: 'capitalize'
},
enquiry:{
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center', 
    backgroundColor: 'lightblue',
    padding: '10px',
    borderRadius: '5px' ,
},

homescreenbuttons:{
    color: 'white',
    marginLeft:'20px',
    textTransform: 'capitalize',
    width:'90px' 


},


achievement:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '10px',
    borderRadius: '5px',
    justifyContent: 'center',
     alignItems: 'center'
},
videoContainerStyle:{
        position: 'relative',
        
    

},
overlayTextStyle:{

    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color:"#fff"
},

textonvideo:{
    textAlign: 'center',
    //backgroundColor:'black'
},


contactcard:{
    display: 'flex',
    justifyContent: 'flex-end',
    //margin:'10px',
    paddingLeft:'120px',
    paddingTop:'30px'
},

cardbuttonstyle:{
    backgroundColor:'#00668F',
    color:'white',
    textTransform: 'capitalize',
    width:'90px',
},
cardcontainer:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
},  
cardimages:{
    display: 'flex',
     alignItems: 'center',
     padding:'7px 0 7px 0'
   
},

reviewcard:{
//display:"flex",
justifyContent:"center",
alignItems:"center",

},

textinformationsStyle:{
    fontWeight: 'bold',
},

reviewcardbuttonposition:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    paddingTop:'20px',
    padding:'20px'

},

reviewcardbutton:{
   
    backgroundColor:'#00668F',
    color:'white',
    width:'90px'
}
}