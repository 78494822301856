import { Box,TextField } from '@mui/material'
import React from 'react'

function Xtextfield({label,height,color,width}) {
  return (
    <Box padding='5px'>
    <TextField style={{height:height,color:color,width:width, backgroundColor:'white'}}
            id="outlined-password-input"
            label={label}
            
          />
          </Box>
  )
}

export default Xtextfield