import { Grid, Typography,Box,Container, createTheme, ThemeProvider,AppBar,Toolbar } from '@mui/material'
import React from 'react'
import { Button } from 'react-bootstrap'
//import goldloan from '../images/goldloan.jpg'
import applyformbg from '../images/applyformbg.jpg'
import Xaccordian from '../../components/accordian/Xaccordian'
import { theme } from '../../common utils/Colors'
import { NavbarStyle } from '../../components/Navbar/NavbarStyle'
import NavbarMain from '../../components/Navbar/NavbarMain'
import Footer1 from '../../components/Footer/Footer1'
import Footer2 from '../../components/Footer/Footer2'
import Footer from '../../components/Footer/Footer'

export default function Goldloan({imagsrc1,title1,title2,producttype,icon1,
  icon2,icon3,icontitle1,icontitle2,icontitle3,griditem,boxitem}) {
    const defaultTheme= createTheme({
      typography: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
      },
    })
   
  
  
    return (
      <ThemeProvider theme={defaultTheme}>
<div>
<AppBar style={{...NavbarStyle.navbarStyle,boxShadow:"0px 3px 5px darkgrey",}}>
    <Toolbar>
    <Container >
       <NavbarMain/>
     </Container>
     </Toolbar>
  </AppBar>
 <Box paddingTop={1}>
    <Container >
      
    <Grid style={{backgroundColor:theme.BODY_COLOR,}}>
         <div style={{ position: 'relative', display: 'inline-block' }}>
          {/* banner image*/}
      <img src={imagsrc1} alt="goldloan" style={{ width: '100%', height: 'auto' }} />
     
    </div>
        
         {/* title & discription*/}
       
         <Box paddingTop={10}>
         <Typography variant="h4" fontWeight="bold" align='center'style={{fontFamily:theme.FONT_FAMILY}}
         >{title1}</Typography>
         </Box>
         <Box justifyContent="flex-start" padding="10px 65px 10px 56px">
            <Typography  style={{fontFamily:theme.FONT_FAMILY}}>{title2}</Typography>
         </Box>

          {/* title & discription*/}
      <Box padding={8}>
      <Typography variant="h4" fontWeight="bold">FAQ</Typography>
      {griditem}
      </Box> 
      {/* Button*/}  
      
    <Box align='center' paddingBottom={5}>
      
        {boxitem}
        </Box>
     </Grid>
     <br/>
     <br/>
    
     <br/>
     <br/>
     </Container>
     <Footer1/>
     <Footer2 footerbg={theme.FOOTER_COLOR}/>
    


     </Box>
    </div>
    </ThemeProvider>
  )
}
