import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Cardlayout from "../Cards/CardLayout";
import avatar1 from '../../pages/images/avatar/avatar1.jpg'
import avatar2 from '../../pages/images/avatar/avatar2.jpg'
import avatar3 from '../../pages/images/avatar/avatar3.jpg'
import { Typography,Box } from "@mui/material";
import { Margin } from "@mui/icons-material";
import { theme } from "../../common utils/Colors";

const Multicarousel = ({ deviceType }) => {
  const cardData = [
    {
      id: 1,
      image: avatar1,
      tittle: "Vikas Money always gives me a helping hand to fulfill my dreams."

    },
    {
      id: 2,
      image: avatar2,
      tittle: "Vikas Money always gives me a helping hand to fulfill my dreams."

    },
    {
      id: 3,
      image: avatar3,
      tittle: "Vikas Money always gives me a helping hand to fulfill my dreams."

    },
    {
      id: 4,
      image: avatar3,
      tittle: "Vikas Money always gives me a helping hand to fulfill my dreams."

    },
    {
      id: 5,
      image: avatar2,
      tittle: "Vikas Money always gives me a helping hand to fulfill my dreams."

    },
    // avatar1,avatar2,avatar3

  ]
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    
    <Carousel 
      swipeable={true}
      draggable={true}
      showDots={true}
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={deviceType !== "mobile" ? true : false}
      autoPlaySpeed={2000}
      keyBoardControl={true}
     // customTransition="all .5"
      transitionDuration={5000}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
      deviceType={deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-0px"
     
    >
   
      {cardData.map((item, index) => (
        <div style={{paddingBottom:'2px'}}>
        <Cardlayout imageSrc={item.image} title1={item.tittle} key={index}
          textFont1='16px'
          txt2weight='bold'
          cardHeight='180px'
          cardWidth='95%'
          textFont2='16px'
          colortx1={theme.BRAND_COLOR1}
          cardradius='10px'
          cardborder='2px solid #ddd' />
          </div>
      ))}
      
    </Carousel>
  );
};

export default Multicarousel;
