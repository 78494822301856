import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { accordiaStyle } from './Xaccordianstyle';


function Xaccordian({title,content}) {
  return (
    <div className="accordion__item" style={{paddingBottom:'5px'}}>
      <Accordion  >
        <AccordionSummary expandIcon={< ExpandMoreIcon  style={{color:'#fff'}}/>}style={accordiaStyle.background}>
          {title}
        </AccordionSummary>
        <AccordionDetails>
          {content}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Xaccordian;
